.map-screen {
  height: 100%;
  width: 100%;
  background: url('gmapsplaceholder.png') no-repeat center center fixed;
  font-family: 'Rubik', sans-serif;
  margin-top: -16px;
}

.headingbox {
  background: white;
  border-radius: 15px;
}

.container {
  padding-bottom: 15px;
}

.git-thing {
  padding-top: 40px;
  padding-left: 25px;
}

.exImage {
  height: 200px;
}

.exImage img {
  height: 120px;
}

.help-btn{
  color: white;
  width: 600px;
  text-align: center;
  font-size: 16px;
  background-color: rgb(94, 233, 39);
  padding: 15px 25px;
  border-radius: 5px;
  margin: 20px;
}

.help-btn-2 {
  background-color: rgb(47, 96, 255);
}

.btns svg {
  display: none;
}

.help-btn:hover{
  cursor: pointer;
  background-color: white;
  color: black;
}

h1 {
  margin-top: 2.5rem !important;
  margin-bottom: 3rem !important;
}

@media only screen and (min-width: 900px) {
  .help-btn{
    font-size: 18px;
  }

  .maxw {
    width: 900px;
  }
  .imageflex {
    flex-direction: row;
  }

  .btns svg {
    display: block !important;
  }
  
  svg {
    display: unset !important;
    margin: 20px !important;
    font-size: 3.5rem !important;
  }
  .exImage {
    height: 275px;
    font-size: 14px;
  }

  .exImage img {
    height: 200px;
  }
}